import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Home: "Home",
      About: "About",
      News: "News",
      Search: "Search",
      previous: "previous",
      next: "next",
      signUp: "sign up",
      password: "password",
      confirmPassword: "password confirmation",
      confirmPasswordRequired: "password confirmation is required",
      existingEmailAdress: "this email adress already used",
      passwordType: 'Please enter at least 8 characters, one letter, one number, one uppercase and one special character',
      emailRequired: 'Please enter your email',
      emailType: 'Enter a valid email',
      passwordRequired: 'Please enter your password',
      passwordMismatch: 'Passwords do not match',
      homePage: {
        needHelpTitle: "need help for a hair routine ?",
        coachingTeaser: "zawema hair coaching goes along with you to help you take care of your hair, especially if those are curly or frizzy"
      },
      contactPage: {
        contactUs: "contactUs"
      },
      faqPage: {
        faq: "frequently asked questions"
      },
      Delivery: "Delivery",
    },
  },
  fr: {
    translation: {
      Home: "Accueil",
      About: "A propos",
      News: "Nouvelles",
      Search: "Recherche",
      previous: "précédent",
      next: "suivant",
      signUp: "s'inscrire",
      password: "mot de passe",
      confirmPassword: "confirmation de mot de passe",
      confirmPasswordRequired: "confirmation de mot de passe requise",
      existingEmailAdress: "cette adresse email est déjà utilisée",
      passwordType: 'Veuillez saisir au moins 8 caractères, une lettre, un chiffre, une majuscule et un caractère spécial',
      emailRequired: 'Veuillez saisir votre email',
      emailType: 'Veuillez renseigner un format e-mail valide',
      passwordRequired: 'Veuillez saisir votre mot de passe',
      passwordMismatch: 'Les mots de passe ne correspondent pas',
      homePage: {
        needHelpTitle: "besoin d'aide pour votre routine capillaire ?",
        coachingTeaser: "le coaching capillaire zawema est un accompagnement pour vous aider à prendre soin de vos cheveux bouclés, crépus ou frisés."
      },
      contactPage: {
        contactUs: "contactez-nous"
      },
      faqPage: {
        faq: "foire aux questions"
      },
      Delivery: "Livraison",
    },
  },
};

export default function prepareI18n(lang) {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
  return i18n;
}
